import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';
import HouseCard from '../../atoms/HouseCard';
import HouseCard4ListView from '../../atoms/HouseCard2';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import ContactButton from '../../../contentful/content-types/ContactButton';
import getObjectImagePath from '../../../utils/getObjectImagePath';
import formatNumber from "../../../utils/formatNumber";

const BuyListPageXS = (props) => {
  const { houseTypes, municipalities, biddingInfo } = props;
  let data = [...props.data];
  let soldObjects = [...props.soldObjects];

  data = data.sort((a, b) => {
    if (a.IsBroker > b.IsBroker) return -1;
    if (a.IsBroker < b.IsBroker) return 1;
    if (a.AddDate > b.AddDate) return -1;
    return 1;
  });
  soldObjects = soldObjects.sort((a, b) => {
    if (a.AddDate > b.AddDate) return -1;
    return 1;
  });
  const MapWithNoSSR = dynamic(() => import('./map'), {
    ssr: false,
  });
  const [viewMode, setViewMode] = React.useState(1);
  return (
    <div>
      <Box sx={{ mt: '50px' }}>
        <Box
          sx={{
            width: '100%',
            height: '180px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url('/images/searchBanner.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                zIndex: '10',
                my: 'auto',
                marginLeft: '16px',
              }}
            >
              <Typography
                component="h1"
                className="font-Avenir-Regular"
                sx={{
                  fontSize: { md: '64px', xs: '32px' },
                  maxWidth: { xs: '330px', md: '600px' },
                  color: 'white',
                  fontWeight: { md: 900, xs: 800 },
                  lineHeight: { md: '76px', xs: '37px' },
                  marginBottom: '16px',
                  textShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.65);',
                }}
              >
                Bostäder till salu
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
      {viewMode === 2 ? (
        <Box
          sx={{
            marginTop: '50px',
            marginBottom: '50px',
            position: 'relative',
            height: '80vh',
            zIndex: 0,
          }}
        >
          <MapWithNoSSR
            data={data}
            soldObjects={soldObjects}
            municipalities={municipalities}
          />
        </Box>
      ) : (
        <>
          <Grid container rowSpacing={3} columnSpacing={1} p={2}>
            {(
              data
                .slice(0, 6) as any
            ).map((item: any, i: number) => {
              const url = `/${item.Slug}`;
              return (
                <Grid item xs={12} key={i}>
                  <Zoom>
                    <Link href={url} passHref legacyBehavior>
                      <Box
                        p="5px"
                        component="a"
                        sx={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {viewMode === 1 && (
                          <HouseCard
                            imagePath={getObjectImagePath(item.images, 1)}
                            name={
                              (!!item.AreaName ? item.AreaName + ', ' : '') +
                              municipalities.find(
                                (type) =>
                                  type.MunicipalityID === item.MunicipalityID
                              ).Name
                            }
                            address={item.Address}
                            price={item.Price}
                            roomCount={formatNumber(item.NumberOfRooms)}
                            m2={
                              item.TypeID === 1 && item.HouseTypeId === 11
                                ? formatNumber(item.LotArea)
                                : formatNumber(item.Area)
                            }
                            type={
                              houseTypes.find(
                                (i) => i.HouseTypeID === item.HouseTypeId
                              ).Type
                            }
                            bidding={
                              biddingInfo.find(
                                (bid) => bid.id === item.BaseID
                              ) !== undefined &&
                              biddingInfo.find((bid) => bid.id === item.BaseID)
                                .biddingOngoing === true
                            }
                            isPrivate={item.IsBroker === 0 ? true : false}
                            showings={item.showings}
                            status={item.Status}
                          />
                        )}
                        {viewMode === 0 && (
                          <HouseCard4ListView
                            imagePath={getObjectImagePath(item.images, 1)}
                            name={
                              (!!item.AreaName ? item.AreaName + ', ' : '') +
                              municipalities.find(
                                (type) =>
                                  type.MunicipalityID === item.MunicipalityID
                              ).Name
                            }
                            address={item.Address}
                            price={item.Price}
                            roomCount={formatNumber(item.NumberOfRooms)}
                            m2={
                              item.TypeID === 1 && item.HouseTypeId === 11
                                ? formatNumber(item.LotArea)
                                : formatNumber(item.Area)
                            }
                            type={
                              houseTypes.find(
                                (i) => i.HouseTypeID === item.HouseTypeId
                              ).Type
                            }
                            bidding={
                              biddingInfo.find(
                                (bid) => bid.id === item.BaseID
                              ) !== undefined &&
                              biddingInfo.find((bid) => bid.id === item.BaseID)
                                .biddingOngoing === true
                            }
                            isPrivate={item.IsBroker === 0 ? true : false}
                            showings={item.showings}
                            status={item.Status}
                          />
                        )}
                      </Box>
                    </Link>
                  </Zoom>
                </Grid>
              );
            })}
          </Grid>

          <Box
            mx={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',

              height: { lg: '394px', xs: '180px' },
              backgroundColor: 'rgba(49, 57, 76, 1)',
            }}
          >
            <Typography
              component="p"
              className="font-Lato-Bold"
              sx={{
                fontSize: { xs: '16px', lg: '36px' },
                color: 'white',
                textAlign: 'center',
              }}
            >
              Funderar du på att sälja din bostad?
            </Typography>
            <Typography
              component="p"
              className="font-Lato-Regular"
              sx={{
                fontSize: { xs: '12px', lg: '24px' },
                marginTop: { lg: '55px', xs: '9px' },
                marginBottom: { lg: '72px', xs: '22px' },
                textAlign: 'center',
                color: 'white',
              }}
            >
              Låt oss kontakta dig för fri rådgivning.
            </Typography>
            <ContactButton title={'Kontakt'}></ContactButton>
          </Box>

          <Grid container rowSpacing={3} columnSpacing={1} p={2}>
            {(
              data
                .slice(6, data.length) as any
            ).map((item: any, i: number) => {
              const url = `/${item.Slug}`;
              return (
                <Grid item xs={12} key={i}>
                  <Zoom>
                    <Link href={url} passHref legacyBehavior>
                      <Box
                        p="5px"
                        component="a"
                        sx={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {viewMode === 1 && (
                          <HouseCard
                            imagePath={getObjectImagePath(item.images, 1)}
                            name={
                              (!!item.AreaName ? item.AreaName + ', ' : '') +
                              municipalities.find(
                                (type) =>
                                  type.MunicipalityID === item.MunicipalityID
                              ).Name
                            }
                            address={item.Address}
                            price={item.Price}
                            roomCount={formatNumber(item.NumberOfRooms)}
                            m2={
                              item.TypeID === 1 && item.HouseTypeId === 11
                                ? formatNumber(item.LotArea)
                                : formatNumber(item.Area)
                            }
                            type={
                              houseTypes.find(
                                (i) => i.HouseTypeID === item.HouseTypeId
                              ).Type
                            }
                            bidding={
                              biddingInfo.find(
                                (bid) => bid.id === item.BaseID
                              ) !== undefined &&
                              biddingInfo.find((bid) => bid.id === item.BaseID)
                                .biddingOngoing === true
                            }
                            isPrivate={item.IsBroker === 0 ? true : false}
                            showings={item.showings}
                            status={item.Status}
                          />
                        )}
                        {viewMode === 0 && (
                          <HouseCard4ListView
                            imagePath={getObjectImagePath(item.images, 1)}
                            name={
                              (!!item.AreaName ? item.AreaName + ', ' : '') +
                              municipalities.find(
                                (type) =>
                                  type.MunicipalityID === item.MunicipalityID
                              ).Name
                            }
                            address={item.Address}
                            price={item.Price}
                            roomCount={formatNumber(item.NumberOfRooms)}
                            m2={
                              item.TypeID === 1 && item.HouseTypeId === 11
                                ? formatNumber(item.LotArea)
                                : formatNumber(item.Area)
                            }
                            type={
                              houseTypes.find(
                                (i) => i.HouseTypeID === item.HouseTypeId
                              ).Type
                            }
                            bidding={
                              biddingInfo.find(
                                (bid) => bid.id === item.BaseID
                              ) !== undefined &&
                              biddingInfo.find((bid) => bid.id === item.BaseID)
                                .biddingOngoing === true
                            }
                            isPrivate={item.IsBroker === 0 ? true : false}
                            showings={item.showings}
                            status={item.Status}
                          />
                        )}
                      </Box>
                    </Link>
                  </Zoom>
                </Grid>
              );
            })}

            {(
              soldObjects
                .slice(0, 20) as any
            ).map((item: any, i: number) => {
              const url = `/${item.Slug}`;
              return (
                <Grid item xs={12} key={i}>
                  <Zoom>
                    <Link href={url} passHref legacyBehavior>
                      <Box
                        p="5px"
                        component="a"
                        sx={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {viewMode === 1 && (
                          <HouseCard
                            imagePath={getObjectImagePath(item.images, 1)}
                            name={
                              (!!item.AreaName ? item.AreaName + ', ' : '') +
                              municipalities.find(
                                (type) =>
                                  type.MunicipalityID === item.MunicipalityID
                              ).Name
                            }
                            address={item.Address}
                            price={item.Price}
                            roomCount={formatNumber(item.NumberOfRooms)}
                            m2={
                              item.TypeID === 1 && item.HouseTypeId === 11
                                ? formatNumber(item.LotArea)
                                : formatNumber(item.Area)
                            }
                            type={
                              houseTypes.find(
                                (i) => i.HouseTypeID === item.HouseTypeId
                              ).Type
                            }
                            bidding={
                              biddingInfo.find(
                                (bid) => bid.id === item.BaseID
                              ) !== undefined &&
                              biddingInfo.find((bid) => bid.id === item.BaseID)
                                .biddingOngoing === true
                            }
                            isPrivate={item.IsBroker === 0 ? true : false}
                            showings={item.showings}
                            status={item.Status}
                          />
                        )}
                        {viewMode === 0 && (
                          <HouseCard4ListView
                            imagePath={getObjectImagePath(item.images, 1)}
                            name={
                              (!!item.AreaName ? item.AreaName + ', ' : '') +
                              municipalities.find(
                                (type) =>
                                  type.MunicipalityID === item.MunicipalityID
                              ).Name
                            }
                            address={item.Address}
                            price={item.Price}
                            roomCount={formatNumber(item.NumberOfRooms)}
                            m2={
                              item.TypeID === 1 && item.HouseTypeId === 11
                                ? formatNumber(item.LotArea)
                                : formatNumber(item.Area)
                            }
                            type={
                              houseTypes.find(
                                (i) => i.HouseTypeID === item.HouseTypeId
                              ).Type
                            }
                            bidding={
                              biddingInfo.find(
                                (bid) => bid.id === item.BaseID
                              ) !== undefined &&
                              biddingInfo.find((bid) => bid.id === item.BaseID)
                                .biddingOngoing === true
                            }
                            isPrivate={item.IsBroker === 0 ? true : false}
                            showings={item.showings}
                            status={item.Status}
                          />
                        )}
                      </Box>
                    </Link>
                  </Zoom>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </div>
  );
};

export default BuyListPageXS;
