import React, { ReactNode } from 'react';
import Head from 'next/head';
import CustomerMessage from '../../../contentful/content-types/CustomerMessage';
import Navbar from '../../../contentful/content-types/Navbar';
import Footer from '../../../contentful/content-types/Footer';
import { Box } from '@mui/material';
import SaljaContent from '../SaljaContent';
import TillSaluContent from '../TillSaluContent';
import Hero from '../../../contentful/content-types/Hero';

const StaticPage = ({ slug, title, description, data }) => {
  let pageContent: ReactNode;

  switch (slug) {
    case 'salja':
      pageContent = <SaljaContent />;
      break;
    case 'till-salu':
      pageContent = <TillSaluContent />;
      break;
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Head>
      <Box sx={{ width: '100%', position: 'fixed', zIndex: '20', top: '0px' }}>
        <CustomerMessage bgColor="gray" />
        <Navbar />
      </Box>
      <Box sx={{ mt: { xs: '60px', lg: '80px' } }}>
        {data !== null && data.fields && slug === 'salja' && (
          <Hero
            heroImage={data.fields.heroImage ? data.fields.heroImage : null}
            heroTitle={data.fields.heroTitle ? data.fields.heroTitle : null}
            heroText={data.fields.heroText ? data.fields.heroText : null}
            heroButtons={
              data.fields.heroButtons ? data.fields.heroButtons : null
            }
            heroBackgroundColor={data.fields.heroBackgroundColor ? data.fields.heroBackgroundColor : null}
            heroTextBelowButtons={data.fields.heroTextBelowButtons ? data.fields.heroTextBelowButtons : null}
            heroRightImage={data.fields.heroRightImage ? data.fields.heroRightImage : null}
            heroSmallImage={data.fields.heroSmallImage ? data.fields.heroSmallImage : null}
          />
        )}

        {pageContent}
      </Box>
      <Footer />
    </>
  );
};

export default StaticPage;
