import React from 'react';
import Head from 'next/head';
import Page from '../contentful/content-types/Page';
import NotFound from '../components/organs/NotFound';
import Navbar from '../contentful/content-types/Navbar';
import Footer from '../contentful/content-types/Footer';
import createSlug from '../utils/createSlug';
import StaticPage from '../components/organs/StaticPage';
import ObjectPage from '../components/organs/ObjectPage';
import HouseInfo from '../components/organs/ObjectPage/HouseInfo';
import {wrapper} from '../redux/store';
import {
  fetchBiddingInfo,
  fetchObjects,
  fetchAssets,
  fetchEntries,
  fetchSoldObjects,
  fetchHouseAssociationTypes,
  fetchCoaAssociationTypes,
  fetchMunicipalities,
  fetchHouseTypes, fetchObjectSlugs, fetchEntriesBySlug
} from '../redux/slices/mainSlice';
import axios from 'axios';
import createUrlFromContext from "../utils/createUrlFromContext";
import reportPageView from "../utils/reportPageView";

const AllPages = (props) => {
  const { pageType, data, slug } = props;
  const { currentPage, baseId, houseType, objectSlug } = data;

  if (pageType === 'STATIC_PAGE') {
    const title =
      slug === 'till-salu'
        ? 'Bostäder till salu'
        : currentPage
        ? currentPage.fields.metaTitle
        : '';
    const description =
      slug === 'till-salu'
        ? 'Hus, lägenhet, fritidshus och tomter säljes privat.'
        : currentPage
        ? currentPage.fields.metaDescription
        : '';
    return (
      <StaticPage
        slug={slug}
        title={title}
        description={description}
        data={currentPage}
      />
    );
  }
  if (pageType === 'NOT_FOUND') {
    return <NotFound />;
  }
  if (pageType === 'OBJECT_PAGE')
    return (
      <>
        <ObjectPage baseId={baseId} houseType={houseType} slug={objectSlug} />
      </>
    );
  if (pageType === 'OBJECT_PAGE_API')
    return (
      <>
        <Navbar />
        <HouseInfo data={data} />
        <Footer />
      </>
    );

  return (
    <>
      <>
        {currentPage === null ? (
          <NotFound />
        ) : (
          <>
            <Head>
              <title>{currentPage.fields.metaTitle}</title>
              <meta
                name="description"
                content={currentPage.fields.metaDescription}
              />
            </Head>
            <Page data={currentPage} />
          </>
        )}
      </>
    </>
  );
};



export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async (context) => {
    const referer = context.req.headers.referer;

    const slug = ((context.query.slug || []) as string[]).join('/');
    const slugs = (context.query.slug || []) as string[];

    await Promise.all([
      store.dispatch(fetchEntries()),
      // TODO: Migrate to fetchPageBySlug instead of fetchEntries
      // store.dispatch(fetchEntriesBySlug(context.query.slug ? slug : '')),
      store.dispatch(fetchAssets()),
      store.dispatch(fetchObjects()),
      store.dispatch(fetchSoldObjects()),
      store.dispatch(fetchHouseAssociationTypes()),
      store.dispatch(fetchCoaAssociationTypes()),
      store.dispatch(fetchMunicipalities()),
      store.dispatch(fetchHouseTypes()),
      store.dispatch(fetchObjectSlugs()),
      store.dispatch(fetchBiddingInfo())
    ])

    // Handle short hand url:s (e g pm.se/11508)
    if (/^\d+$/.test(slug)) {
      // Lookup this baseId the API
      const object = await axios
        .get(
          `${
            process.env.NEXT_PUBLIC_API_ENDPOINT
          }v2/object/byId/${slug}`
        )
        .then(res => res.data)
        .catch(err => null);

      if (object) {
        return {
          redirect: {
            permanent: false,
            destination: object.Slug,
          },
        };
      }
    }

    const res = store.getState().main.entries;
    const houseTypes = store.getState().main.houseTypes;
    const objectEntries = res.filter(
      (item) => item.sys.contentType.sys.id === 'page'
    );
    const index = objectEntries
      .map((item) => (item.fields as any).slug ?? '')
      .indexOf(slug);
    const currentPage = index >= 0 ? objectEntries[index] : null;
    let pageType = 'NOT_FOUND';
    if (currentPage !== null) pageType = 'CONTENTFUL_PAGE';
    pageType =
      slug === 'salja' || slug === 'till-salu'
        ? 'STATIC_PAGE'
        : pageType;
    houseTypes.forEach((element) => {
      if (slugs[0] === createSlug(element.Type)) pageType = 'OBJECT_PAGE';
    });
    if (slugs.length !== 2 && pageType === 'OBJECT_PAGE')
      pageType = 'NOT_FOUND';
    let baseId = '';
    if (pageType === 'OBJECT_PAGE') {
      const url = createUrlFromContext(context);
      baseId = url.split('?').at(0).split('-').at(-1);
      // Report statistics for this baseId
      // Ignore garbage from old pages like "brunortsvagen_11_bid10199"
      if (parseInt(baseId)) {
        try {
          reportPageView(referer, baseId, url);
        } catch(e) {
          // The available referer/query string could not be parsed successfully
        }
      }
    }
    const objectExist = !!!store
      .getState()
      .main.objects.map((i) => i.BaseID)
      .filter((i) => i === baseId).length;
    if (
      pageType === 'NOT_FOUND' ||
      (pageType === 'OBJECT_PAGE' && objectExist)
    ) {
      let objectFromAPI;
      await axios
        .get(
          `${
            process.env.NEXT_PUBLIC_API_ENDPOINT
          }v2/object/${encodeURIComponent(slug)}`
        )
        .then((res) => (objectFromAPI = res))
        .catch((err) => (objectFromAPI = undefined));

      if (objectFromAPI !== undefined) {
        return {
          props: {
            pageType: 'OBJECT_PAGE_API',
            slug,
            data: objectFromAPI.data,
          },
        };
      }
    }
    let data = {};
    switch (pageType) {
      case 'STATIC_PAGE': {
        data = {
          currentPage,
        };
        break;
      }
      case 'CONTENTFUL_PAGE': {
        data = {
          currentPage
        };
        break;
      }
      case 'OBJECT_PAGE': {
        data = {
          baseId,
          houseType: slugs[0],
          objectSlug: slugs[1],
        };
        break;
      }
    }

    return {
      props: {
        pageType,
        slug,
        data,
      },
    };
  }
);

export default AllPages;
